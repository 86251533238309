<template>
  <v-footer
    dark
    color="secondary"
    :class="{ 'd-none': $route.path == '/home-two' }"
    class="pa-8"
  >
    <v-container>
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="4" sm="6" class="pr-16">
          <img src="@/assets/images/your-logo.png" width="180" class="mt-2 mb-5" alt="" style="opacity: 0.5;" /> <br />
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-1"
          >
            <v-icon small size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mb-5">About Us</h2>
          <div v-for="(n, index) in aboutUs" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{ n }}</a>
          </div>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mb-5">Customer Care</h2>
          <div v-for="(n, index) in customerCare" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{ n }}</a>
          </div>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mb-5">Contact Us</h2>
          <p class="footer-text">
            4Level 3 <br/>
            22 Market Street <br/>
            Sydney NSW 2000 <br/>
            Australia <br/>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-youtube", "mdi-google", "mdi-instagram"],
    aboutUs: ["Careers", "Our Stores", "Terms & Conditions", "Privacy Policy"],
    customerCare: ["Help Center", "How to Buy", "Track Your Order", "Returns & Funds"],
  }),
};
</script>
<style lang="scss">
a.footer-link-hover {
  color: #bdbdbd !important;
  &:hover {
    color: #fff !important;
  }
}
.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
</style>
